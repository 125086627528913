﻿.module-content-highlight-container {
    width: 100%;
    max-height: 200px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    border-radius: 10px;
}

.module-content-highlight {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img {
        width: 100%;
    }

    .module-content-highlight-overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        padding: 15px;
        transition: 0.2s ease-in-out;

        h5 {
            color: var(--textColorLight);
        }

        p {
            color: var(--textColorLight);
        }
    }
}

.module-content-marketplace {
    padding: var(--padding2);
    width: 100%;
    display: flex;
    column-gap: 15px;
    row-gap: 15px;
    flex-flow: column wrap;
}

.marketplace-productContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    width: 100%;
    /*gap: 25px;*/
    grid-column: 1 / -1;
}

.marketplace-product-description-productInfo {
    ul {
        margin: 0;
        padding-left: 15px;
        line-height: 1.5em;
    }
}


/* Listview */
.marketplace-productContainer-listView {
    .marketplace-product {
        width: 100%;
        display: flex;
        column-gap: 15px;
        row-gap: 15px;
        border-bottom: var(--borderBottom1);
        padding: 0 0 50px 0;
        margin: 0;
        /*flex-flow: row wrap;*/
        grid-column: 1 / -1;

        &:last-child {
            border-bottom: none;
            padding: 0;
            margin: 0;
        }

        .marketplace-product-image {
            /*width: 20%;
            max-width: 220px;
            height: fit-content;*/

            img {
                width: 120px;
                height: auto;
                max-height: 120px;
                border-radius: 10px;
                /*position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: contain;
                width: 100%;
                height: 100%;*/
            }
        }

        .marketplace-product-description {
            width: 100%;
            display: flex;
        }

        .marketplace-product-description-productInfo {
            width: 100%;
            display: flex;
            flex-flow: column wrap;
            /*padding: 15px 0;*/
            padding: 0;
            margin: 0;

            h5 {
                margin: 0 0 0.3em 0;
            }
        }

        .marketplace-product-description-priceInfo {
            width: 30%;
            min-width: fit-content;
            display: flex;
            flex-flow: column wrap;
            /*padding: 15px 15px 15px 0;*/
            padding: 0;
            align-items: flex-end;
            row-gap: 5px;

            p {
                width: 100%;
                text-align: right;
                margin: 0;
            }

            .marketplace-product-description-priceInfo-price {
                width: 100%;
                display: inline-flex;
                flex-flow: column wrap;

                h3 {
                    text-align: right;
                    margin: 0;
                }

                h4 {
                    text-align: right;
                    margin: 0 0 10px 0;
                }
            }
        }
    }
}


/* Gridview */

.marketplace-productContainer-gridView {
    /*display: flex;
    row-gap: 50px;
    justify-content: flex-start;*/

    .marketplace-product {
        /*width: calc((100% / 3) - 20px);*/
        /*width: clamp(260px, 50%, 320px);*/
        width: 100%;
        row-gap: 25px;
        display: flex;
        flex-flow: row wrap;
        /*padding: 10px;*/
        align-content: flex-start;

        &:last-child {
        }

        .marketplace-product-image {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            border-radius: 10px;
            background: var(--backgroundColor2);
            overflow: hidden;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .marketplace-product-description-productInfo {
            width: 100%;

            & > *:not(:last-child) {
                margin: 0 0 10px 0;
            }

            h5 {
                /*word-break: break-all;*/
                hyphens: auto;
                margin: 0 0 0.5em 0;
            }

            p {
                margin: 0;
            }
        }

        .marketplace-product-description-priceInfo {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;

            p {
                width: 30%;
                order: 4;
                margin: 0;
                text-align: right;
            }

            .marketplace-product-description-priceInfo-price {
                width: 70%;
                order: 1;
                margin: 0;

                h3 {
                    margin: 0 0 0.3em 0;
                }
            }

            .addToCartBtn {
                order: 2;
                margin-left: auto;
            }

            .productRating {
                width: 70%;
                order: 3;
                justify-content: flex-start;
            }
        }
    }
}

.marketplace-header {
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
    flex-flow: row wrap;

    h5 {
        position: unset;
        top: unset;
        left: unset;
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
        margin: 0 auto 0 0;
        padding: 0 15px;
    }

    button i {
        width: 13px;
        margin: 0;
    }
}




.module-content-tab-filter {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding: 15px 15px 0 15px;
    justify-content: flex-end;

    button {
        margin: 0;

        i {
            width: 13px;
            margin: 0 !important;
        }
    }
}



.marketplace-sort {
    display: inline-flex;
    align-items: center;

    p {
        margin: 0 10px 0 0;
    }

    select {
        background: var(--backgroundColor2);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22caret-down%22%20class%3D%22svg-inline--fa%20fa-caret-down%20fa-w-10%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M31.3%20192h257.3c17.8%200%2026.7%2021.5%2014.1%2034.1L174.1%20354.8c-7.8%207.8-20.5%207.8-28.3%200L17.2%20226.1C4.6%20213.5%2013.5%20192%2031.3%20192z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
        background-position: right 1.1em top 54%, 0 0;
        background-size: .65em auto, 100%;
        background-repeat: no-repeat, repeat;
        font-weight: var(--fontWeight2);
        /*font-size: var(--inputFontSize);*/
        float: left !important;
        border: none;
        color: $default-font-color-dark;
        padding: var(--inputPadding);
        margin: 0;
        border-radius: var(--roundCorner2);
    }
}

.marketplace-filter {
    button, a {
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        color: #111;

        &.active {
            color: $default-style-color-1;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        text-decoration: none;

        li {
            padding: 3px 0;
        }
    }

    .marketplace-filter-expendable ul {
        margin: 0;
        padding: 0 0 0 10px;
        list-style: none;
        text-decoration: none;
    }

    .marketplace-filter-expendable-selected-title {
        font-weight: var(--fontWeight3);
    }
}

.marketplace-basket {
    position: absolute;
    top: 0;
    right: 0;
    /*z-index: 200;*/

    button i {
        margin: 0 !important;
        left: -1px;
        position: relative;
    }

    .marketplace-basket-list {
        position: absolute;
        right: 0;
        background: var(--backgroundColor1);
        border-radius: $default-border-radius;
        padding: var(--padding2);
        -webkit-box-shadow: var(--shadow2);
        box-shadow: var(--shadow2);
        min-width: 250px;
        width: -webkit-fit-content;
        width: fit-content;
        width: -moz-fit-content;
        min-width: 300px;

        .admin-only {
            border-top: var(--borderBottom1);
            padding: 10px 0 0 0;
            margin-bottom: 10px;
        }

        .marketplace-basket-list-header {
            margin-bottom: 10px;
            /*margin-bottom: 15px;
            padding-bottom: 10px;
            border-bottom: $default-border-line;*/

            h5 {
                text-align: center;
                margin: 0;
            }
            /*@include size(mobile) {
                h5 {
                    text-align: center;
                }
            }*/

            p {
                text-align: center;

                &:not(:first-child) {
                    margin-top: 5px;
                }
            }
        }

        .marketplace-basket-list-products {
            /*border-top: var(--borderBottom1);
            padding: 10px 0 0 0;

            &:empty {
                border: none;
            }*/
        }

        .marketplace-basket-list-footer {
            border-top: var(--borderBottom1);
            padding: 10px 0 0 0;
        }

        .marketplace-basket-list-btns {
            border-top: var(--borderBottom1);
            padding: 10px 0 0 0;
            display: flex;
            justify-content: center;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                margin-bottom: 10px;

                &:first-child {
                    padding-top: 10px;
                    border-top: var(--borderBottom1);
                }

                p {
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                    -ms-flex-flow: row nowrap;
                    flex-flow: row nowrap;
                    width: 100%;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    margin: 0;

                    &.basket-case-total {
                        border-top: var(--borderBottom1);
                        margin: 0;
                        padding: 10px 0 0 0;
                        display: -webkit-inline-box;
                        display: -ms-inline-flexbox;
                        display: inline-flex;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        width: 100%;
                    }
                }
            }
        }

        .marketplace-basket-list-amount {
            margin: 0 10px 0 0;
            width: 15%;
        }

        .marketplace-basket-list-article {
            margin: 0 10px 0 0;
            font-weight: var(--fontWeight3);
            width: 100%;
        }

        .marketplace-basket-list-price {
            margin: 0 10px 0 0;
        }

        .marketplace-basket-list-remove {
            margin: 0;
            color: #CC3B33;
        }

        .marketplace-basket-list-total {
            margin: 0 0 5px 0;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            width: 100%;

            &:last-child {
                margin-bottom: 10px;
            }

            &.total {
                font-weight: var(--fontWeight3);
                /*color: green;*/
                /*padding: 0 0 50px 0;
                margin: 50px;*/
            }

            span#shipmentCosts, span#totalCosts {
                font-weight: 400;
            }
        }
    }
}

.fullPrice {
    font-size: 15px;
    font-weight: 300;
    text-decoration: line-through;
    margin: 0;
}

.productRating {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;

    span {
        color: var(--buttonColorGrey);

        &.checked {
            color: var(--styleColor3);
        }
    }
}

.addToCartBtn {
    width: fit-content;
    margin: 0;

    i:last-child {
        margin: 0;
    }
}

.hide-highlight {
    /*display: none;*/
    opacity: 0;
}

.marketplace-filter-hide {
    display: none;
}

.marketplace-basket-hide {
    display: none;
}

.cart-overview-list {
    border: none;
    margin: 0;
    padding: 0;
    list-style: none;

    .cart-overview-list-item {
        display: flex;
        width: 100%;
        column-gap: 15px;
        border-bottom: var(--borderBottom1);
        padding: 0 0 10px 0;
        margin: 0 0 10px 0;

        &:last-child {
            border-bottom: none;
            margin: 0;
            padding: 0;
        }

        .cart-overview-list-item-image {
            img {
                height: 60px;
                width: 60px;
                object-fit: contain;
            }
        }

        .cart-overview-list-item-text {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            flex-flow: column wrap;
            overflow: hidden;
            /*word-break: break-all;*/
            hyphens: auto;

            p {
                margin: 0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .cart-overview-list-item-btns {
            display: flex;
            column-gap: 10px;
            align-items: flex-start;
            width: 100%;
            justify-content: flex-start;

            h3 {
                margin: 0;
                font-size: $default-font-size;
                font-weight: var(--fontWeight3);
                height: 32px;
                display: flex;
                align-items: center;
                flex: 1 1 100%;
            }

            p {
                margin: 0;
                min-width: 70px;
                text-align: right;

                span {
                    width: 100%;
                    display: block;
                }
            }

            .deleteFromCart {
                height: 20px;
                width: 20px;
                margin: 6px 0;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;

                i {
                    margin: 0;
                    padding: 0;
                    font-size: 10px;
                }
            }

            .amount-selector {
                height: 32px;
                position: relative;
                flex: 0 0 auto;
            }

            & > *:last-child {
                margin-left: auto !important;
            }
        }
    }
}

.cart-priceTotal-list {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;

    .cart-priceTotal-list-item {
        margin: 0;
        padding: 5px 0;
        display: inline-flex;
        width: 100%;
        justify-content: space-between;

        p {
            margin: 0;
        }

        .cart-priceTotal-list-item-text-price {
            font-weight: var(--fontWeight3);
            /*font-size: 15px;*/
        }

        &.cart-priceTotal-list-item-total {
            border-top: var(--borderBottom1);
            margin: 5px 0 0 0;
        }
    }
}

.notification-number {
    min-width: 20px;
    width: fit-content;
    height: 20px;
    background: var(--buttonColorRed);
    color: var(--textColorLight);
    margin: 0;
    padding: 5px;
    border-radius: 50px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -8px;
    right: 0;
    transform: translate(50%, 0);

    &.empty-cart {
        display: none;
    }
}

.checkout-paymentSelector {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;

    li {
        .checkout-paymentSelector-option-header {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: 100%;
            height: 40px;
            background: var(--backgroundColor2);
            border-radius: 10px;
            margin: 0 0 15px 0;
            padding: 5px 15px;
            font-weight: var(--fontWeight3);
            column-gap: 10px;

            p {
                margin: 0;
            }

            .checkout-paymentSelector-option-header-logos {
                margin: 0;
                margin-left: auto;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                column-gap: 5px;

                img {
                    margin: 0;
                    max-height: 30px;
                    width: auto;
                    height: 100%;
                    background-color: white;
                    border-radius: 5px;
                }
            }

            &.selected {
                background: var(--styleColor3);
                color: $default-font-color-dark;
            }
        }

        .checkout-paymentSelector-option-body {
            display: flex;
            padding: 0 15px 15px 15px;
            flex-flow: row wrap;

            p {
                margin: 0;
            }

            &.hide {
                display: none;
            }
        }
    }
}

#accountPartialPH {
    width: 100%;
}

.switch-marketplace-setting {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    p {
        margin: 0;

        .setting-name {
            font-weight: 700;
        }
    }
}

@include size(desktop) {
    /* 6.11: Marketplace */
    .module-content-tab-filter .marketplace-sort p {
        display: none;
    }

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-image {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo {
        width: 100%;
        padding: 0;
    }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo h5, .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo p {
            /*text-align: center;*/
        }

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo {
        width: 100%;
        padding: 0;
        /*align-items: center;
        flex-flow: unset;*/
    }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .marketplace-product-description-priceInfo-price h3 {
            /*text-align: left;*/
        }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .productRating {
            justify-content: flex-start;
        }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo p {
            text-align: left;
        }

    .marketplace-productContainer-gridView .marketplace-product {
        width: 100%;
    }
}


@include size(mobile) {
    /* 6.11: Marketplace */
    /*.marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo {
        width: 100%;
    }

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo {
        width: 100%;
    }*/
    .marketplace-basket .marketplace-basket-list .marketplace-basket-list-btns {
        justify-content: space-between;
        padding: 20px 0 0 0;
    }

    .marketplace-basket .marketplace-basket-list ul {
        overflow-y: auto;
    }

    .module-content-tab-filter {
        justify-content: center;
    }

        .module-content-tab-filter .marketplace-sort p {
            display: none;
        }

    .marketplace-productContainer-gridView .marketplace-product {
        width: 100%;
    }

    .marketplace-basket > button {
        position: fixed;
        bottom: 25px;
        right: 25px;
        width: 40px;
        height: 40px;
        border: none;
        padding: 0;
        background: var(--buttonColorGreen);
        border-radius: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .marketplace-basket {
        z-index: 150;
    }

        .marketplace-basket > button {
            z-index: 1;
        }

            .marketplace-basket > button > i {
                margin: 0;
                color: var(--textColorLight);
                font-size: 15px;
            }

    .notification-number {
        position: fixed;
        top: unset;
        right: 28px;
        bottom: 48px;
        z-index: 1;
    }

    .marketplace-basket .marketplace-basket-list ul li {
        margin-bottom: 15px;
    }

    .marketplace-basket .marketplace-basket-list .marketplace-basket-list-price {
        margin: 0 20px 0 0;
    }

    .marketplace-basket .marketplace-basket-list .marketplace-basket-list-remove i {
        font-size: 20px;
    }

    .marketplace-basket .marketplace-basket-list {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100vw;
        height: 80vh;
        padding-bottom: 80px;
        -webkit-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
    }

    .marketplace-basket-hide {
        display: unset;
        height: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        overflow: hidden;
    }

    .cart-overview-list .cart-overview-list-item {
        flex-flow: row wrap;
        row-gap: 15px;
        padding: 0 0 20px 0;
        margin: 10px 0 10px 0;
    }

        .cart-overview-list .cart-overview-list-item .cart-overview-list-item-image {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .cart-overview-list .cart-overview-list-item .cart-overview-list-item-text h5, .cart-overview-list .cart-overview-list-item .cart-overview-list-item-text p {
            text-align: center;
        }

        .cart-overview-list .cart-overview-list-item .cart-overview-list-item-btns {
            align-items: center;
            width: 100%;
            flex-flow: row wrap;
            justify-content: center;
            row-gap: 5px;
        }

            .cart-overview-list .cart-overview-list-item .cart-overview-list-item-btns .amount-selector {
                order: 3;
                /*width: 100%;*/
                right: 0;
                justify-content: center;
                margin-top: 15px;
            }

            .cart-overview-list .cart-overview-list-item .cart-overview-list-item-btns h3 {
                order: 1;
                height: unset;
                justify-content: center;
            }

            .cart-overview-list .cart-overview-list-item .cart-overview-list-item-btns .deleteFromCart {
                order: 4;
                margin-top: 21px;
            }

    .cart-overview-list-item-btns > *:last-child {
        margin-left: 20px !important;
    }


    .module-content-comparison-list > li > .module-content-comparison-listItem-title {
        flex-flow: row nowrap;
    }

        .module-content-comparison-list > li > .module-content-comparison-listItem-title > .module-content-comparison-listItem-btns {
            margin: 0;
        }

    #comparison > .module-content > .module-content-tab-content .module-content-comparison-list > li {
        padding-bottom: 10px;
        margin: 0 0 10px 0;
        border-bottom: var(--borderBottom1);
    }

        #comparison > .module-content > .module-content-tab-content .module-content-comparison-list > li:last-child {
            padding-bottom: 0;
            margin: 0;
            border-bottom: none;
        }

    .marketplace-productContainer-gridView .marketplace-product {
        padding: 0;
        border-bottom: var(--borderBottom1);
        padding: 0 0 15px 0;
        margin: 0 0 15px 0;
    }

        .marketplace-productContainer-gridView .marketplace-product:last-child {
            border-bottom: none;
            padding: 0;
            margin: 0;
        }

    .marketplace-productContainer {
        grid-template-columns: unset;
    }

    .marketplace-productContainer-listView .marketplace-product {
        flex-flow: row wrap;
    }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-image {
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            border-radius: 10px;
            background: var(--backgroundColor2);
            overflow: hidden;
            position: relative;
        }

            .marketplace-productContainer-listView .marketplace-product .marketplace-product-image img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: contain;
                width: 100%;
                height: 100%;
                max-height: unset;
            }
        /*.marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo {
        width: 100%;
    }

    .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo p {
        margin: 0;
    }*/

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo {
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }

            .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .marketplace-product-description-priceInfo-price {
                width: 70%;
                order: 1;
                margin: 0;
            }

            .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .addToCartBtn {
                order: 2;
                margin-left: auto;
            }

            .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .marketplace-product-description-priceInfo-price h3 {
                text-align: left;
                margin-bottom: .5rem;
            }

            .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-priceInfo .marketplace-product-description-priceInfo-price h4 {
                text-align: left;
                margin-bottom: .5rem;
            }

        .marketplace-productContainer-listView .marketplace-product .marketplace-product-description-productInfo p {
            margin: 0;
        }
}
