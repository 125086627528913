﻿/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/


.grid-module {
    width: 100%;
    /*@include gridColumns;*/
    /*gap: 50px;*/
    grid-column: 1 / -1;
}

/*.module-container {
    .module-content {
        .grid-module {
            .module-options-dropdown {*/
                /*background: green;*/
                /*top: -47px;*/
            /*}
        }
    }
}*/

.grid-module .module-container {
    grid-column: 1 / -1;
}

.module-dataTable {
    width: 100%;
}

/*.dataTables_wrapper > .row:nth-child(2) {
    overflow-x: hidden;
}

.dataTables_wrapper > .row:nth-child(2):hover {
    overflow-x: scroll;
}*/

.module-dataTable-controls {
    width: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.module-dataTable-content {
    overflow-x: hidden;
}

/*.module-dataTable-btn-filter {
    background: #226285;
    -webkit-box-shadow: 0 5px 7px 0 rgba(34,98,133,0.3);
    -moz-box-shadow: 0 5px 7px 0 rgba(34,98,133,0.3);
    box-shadow: 0 5px 7px 0 rgba(34,98,133,0.3);
}

.module-dataTable-btn-aansluitpunt {
    background: var(--buttonColorGreen);
    -webkit-box-shadow: 0 5px 7px 0 rgba(21,195,154,0.3);
    -moz-box-shadow: 0 5px 7px 0 rgba(21,195,154,0.3);
    box-shadow: 0 5px 7px 0 rgba(21,195,154,0.3);
}

.module-dataTable-btn-edit {
    background: #226285;
    -webkit-box-shadow: 0 5px 7px 0 rgba(34,98,133,0.3);
    -moz-box-shadow: 0 5px 7px 0 rgba(34,98,133,0.3);
    box-shadow: 0 5px 7px 0 rgba(34,98,133,0.3);
}

.module-dataTable-btn-delete {
    background: #CC3B33;
    -webkit-box-shadow: 0 5px 7px 0 rgba(204,59,51,0.3));
    -moz-box-shadow: 0 5px 7px 0 rgba(204,59,51,0.3);
    box-shadow: 0 5px 7px 0 rgba(204,59,51,0.3);
}*/

#saveFilterBtn {
    display: none;
}

.saveFilterOptions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    left: 50%;
    width: 300px;
    padding: 20px;
    z-index: 130;
    -webkit-transform: translate(calc(-50% + 15px), 0px);
    -ms-transform: translate(calc(-50% + 15px), 0px);
    transform: translate(calc(-50% + 15px), 0px);
    border: none;
    border-radius: $default-border-radius;
    background: var(--backgroundColor1);
    color: $default-font-color-dark;
    -webkit-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}

.filterSelect {
    width: 250px;
    padding: 5px 35px 5px 15px;
    border-radius: var(--roundCorner2);
    border: none;
    font-size: var(--inputFontSize);
    height: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: var(--caretInputBackground1);
    background-position: var(--caretInputBackgroundPos1);
    background-size: var(--caretInputBackgroundSize1);
    background-repeat: var(--caretInputBackgroundRepeat1);
    background-color: var(--backgroundColor2);
}

#filterName {
    width: 100%;
    padding: var(--padding4);
    border-radius: var(--roundCorner2);
    border: none;
    font-size: var(--inputFontSize);
    background-color: var(--backgroundColor2);
    margin-bottom: var(--marginBottom3);
    height: var(--inputHeight1);
}

.filterOption {
    width: 100%;
    margin-bottom: var(--marginBottom5);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: var(--columnGap6);
    -moz-column-gap: var(--columnGap6);
    column-gap: var(--columnGap6);
}

.filterOption > label {
    margin: 0;
}

.filterOption-2 {
    width: 100%;
    margin-bottom: var(--marginBottom5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-column-gap: var(--columnGap6);
    -moz-column-gap: var(--columnGap6);
    column-gap: var(--columnGap6);
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
}

.filterOption-2 > label {
    padding: 0 0 0 20px;
}

#saveFilter {
    margin: 0;
    margin-top: var(--marginTop4);
}

.advanced-search .show-on-advanced-search {
    min-height: unset;
}

.module-dataTable-btn-create {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.module-dataTable-btn-create > a {
    margin: 0;
    margin-left: 25px !important;
}

.module-dataTable-btn-addFilter {
    margin: 0 0 0 auto;
}

.module-dataTable-filter-addSetting {
    position: relative;
}

.module-dataTable-filter-text {
    margin: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: auto;
    white-space: nowrap;
    overflow-x: hidden;
}

.module-dataTable-filter-text > span {
    font-weight: var(--fontWeight3);
    margin-right: var(--iconMargin);
}

/*.dataTables_filter, .dataTables_info, .dataTables_length {
    display: none;
}*/

.module-dataTable-controls-filter {
    width: 100%;
    margin-bottom: var(--marginBottom3);
    /*border-top: 2px solid #333;*/
}

.module-dataTable-controls-filter > div > div:last-child {
    display: none;
}

.module-dataTable-controls-filter > div.filter {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    -webkit-column-gap: var(--columnGap3);
    -moz-column-gap: var(--columnGap3);
    column-gap: var(--columnGap3);
    /*margin-bottom: 20px;*/
    border-radius: $default-border-radius;
    background: var(--backgroundColor1);
    padding: 10px 15px;
}

.module-dataTable-controls-filter > div.filter:nth-of-type(2n+1) {
    background: var(--backgroundColor2);
}

.module-dataTable-controls-filter > div.filter:first-child {
    margin-top: var(--marginTop3);
}

.module-dataTable-controls-filter > div.filter > select {
    width: 100%;
    padding: 5px 35px 5px 15px;
    border-radius: var(--roundCorner2);
    border: none;
    font-size: var(--inputFontSize);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: var(--caretInputBackground1);
    background-position: var(--caretInputBackgroundPos1);
    background-size: var(--caretInputBackgroundSize1);
    background-repeat: var(--caretInputBackgroundRepeat1);
    background-color: var(--backgroundColor2);
}


.module-dataTable-controls-filter > div.filter:nth-of-type(2n+1) > select {
    background-color: var(--backgroundColor1);
}

.module-dataTable-controls-filter > div.filter > input {
    width: 100%;
    padding: var(--padding4);
    border-radius: var(--roundCorner2);
    border: none;
    font-size: var(--inputFontSize);
    background-color: var(--backgroundColor2);
    height: var(--inputHeight1);
}

.module-dataTable-controls-filter > div.filter:nth-of-type(2n+1) > input {
    background-color: var(--backgroundColor1);
}

.module-dataTable-controls-filter > div.filter > button {
    margin: 0;
}

.module-dataTable-controls-filter > div.filter > button > i {
    height: unset;
    /*margin: 0;*/
}

.nullCell {
    color: var(--textColorGrey);
}

.module-dataTable-controls > h4 {
    font-family: $default-font-family-headline;
    font-size: var(--titleFontSize);
    font-weight: var(--fontWeight4);
    margin: 0 auto 0 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.module-dataTable-filter {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: var(--marginBottom3);
}

.module-dataTable-location {
    margin: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*width: 100%;*/
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    /*height: 35px;*/
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    font-size: unset;
}

abbr.ellipsis {
    width: fit-content;
    max-width: 20em;
    display: block;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    text-decoration: none;
    margin: 0;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.module-dataTable-filter-addSetting > label > a {
    color: var(--textColorLight);
}

.module-dataTable-table > thead > tr > th, #DataPackage > table > thead > tr > th {
    background: #FFBE0B !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    text-align: center;
    /*border-right: 2px solid #333;*/
}

.module-dataTable-table > thead > tr > th:first-child, #DataPackage > table > thead > tr > th:first-child {
    border-bottom-left-radius: var(--roundCornerBottomLeft1);
    border-top-left-radius: var(--roundCornerTopLeft1);
}

.module-dataTable-table > thead > tr > th:last-child, #DataPackage > table > thead > tr > th:last-child {
    border-bottom-left-radius: var(--roundCornerBottomRight1);
    border-top-right-radius: var(--roundCornerTopRight1);
    /*border-right: none;*/
}

.module-dataTable-controls-filter > .module-dataTable-table > thead > tr > th, .module-dataTable-controls-filter > div > div > div > .module-dataTable-table > thead > tr > th {
    background: var(--buttonColorBlue) !important;
    color: var(--textColorLight);
    font-weight: var(--fontWeight2);
}

.module-dataTable-table-divider {
    height: 15px;
    border-right: var(--borderRight1);
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    opacity: 0.7;
}

.module-dataTable-controls-filter > .module-dataTable-table > thead > tr > th > .module-dataTable-table-divider, .module-dataTable-controls-filter > div > div > div > .module-dataTable-table > thead > tr > th > .module-dataTable-table-divider {
    border-right: var(--borderRight2);
}

.table-divider-2 {
    opacity: 0.1;
}

table.dataTable.table-sm .sorting:before, table.dataTable.table-sm .sorting_asc:before, table.dataTable.table-sm .sorting_desc:before {
    top: 4px;
    right: 16px;
    /*opacity: 1;*/
    content: "\f30c";
    font-family: "Font Awesome 5 Free";
}

table.dataTable.table-sm .sorting:after, table.dataTable.table-sm .sorting_asc:after, table.dataTable.table-sm .sorting_desc:after {
    top: 6px;
    right: 9px;
    /*opacity: 1;*/
    content: "\f309";
    font-family: "Font Awesome 5 Free";
}

.DTFC_LeftBodyWrapper thead th {
    display: none;
}

.module-dataTable-table > tbody > tr, #DataPackage > table > tbody > tr {
    background: var(--backgroundColor2);
}

.module-dataTable-table > tbody > tr:nth-of-type(2n+1), #DataPackage > table > tbody > tr:nth-of-type(2n+1) {
    background: var(--backgroundColor1);
}

.module-dataTable-table > tbody > tr > td, #DataPackage > table > tbody > tr > td {
    border: none;
    text-align: center;
}

.module-dataTable-table > tbody > tr > td:first-child, #DataPackage > tbody > tr > td:first-child {
    border-bottom-left-radius: var(--roundCornerBottomLeft1);
    border-top-left-radius: var(--roundCornerTopLeft1);
}

.module-dataTable-table > tbody > tr > td:last-child, #DataPackage > table > tbody > tr > td:first-child {
    border-bottom-left-radius: var(--roundCornerBottomRight1);
    border-top-right-radius: var(--roundCornerTopRight1);
}

.table.table-sm td, .table.table-sm th {
    padding: 10px 30px;
    position: relative;
    width: 100%;
    white-space: nowrap;
    /*overflow: hidden;*/
    table-layout: fixed;
}

.table.dataTable.table-sm > thead > tr > th {
    padding-right: 30px !important;
    padding-left: 30px;
    position: relative;
    height: 19px;
}

.module-dataTable-noBeforeAfter:before, .module-dataTable-noBeforeAfter:after {
    content: "" !important;
}

#DataPackage > table > thead > tr > th {
    position: relative;
}

.module-dataTable-filter-hidden {
    display: none;
}

.page-item > .page-link {
    border: none;
    border-radius: var(--roundCorner2);
    color: $default-font-color-dark;
    background: none;
    /*    border-top: 2px solid #F5F5F5;
    border-bottom: 2px solid #F5F5F5;
    border-color: #F5F5F5 !important;*/
}

.page-item:first-child .page-link {
    border-radius: var(--roundCorner2);
    margin-right: 20px;
}

.page-item:last-child .page-link {
    border-radius: var(--roundCorner2);
    margin-left: 20px;
}

/*.page-item > .page-link[data-dt-idx="1"] {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    border-top: 2px solid #F5F5F5;
    border-bottom: 2px solid #F5F5F5;
    border-left: 2px solid #F5F5F5;
}

.page-item > .page-link[data-dt-idx="7"] {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    border-top: 2px solid #F5F5F5;
    border-bottom: 2px solid #F5F5F5;
    border-right: var(--borderRight2);
}*/

div.dataTables_wrapper div.dataTables_paginate {
    margin-top: 25px;
}

.page-item.disabled .page-link {
    color: var(--textColorLightGrey);
    background: none;
}

.page-item.active .page-link {
    background-color: var(--buttonColorGreen);
    z-index: unset;
}

.table {
    display: block;
    overflow: auto;
}


@media only screen and (min-width: 950px) {
    .grid-module .dataTables_wrapper  {
        margin-top: 15px;
    }
    .grid-module:not(.advanced-search){
        
        &:not(.disable-grid-filter):not(.disable-grid-pagination) .dataTables_wrapper {
            margin-top: -45px;
        }
        &.disable-grid-pagination .dataTables_wrapper {
            margin-top: -15px;
        }
        
    }
}

.dataTables_wrapper .dataTables_filter input[type="search"] {
    padding: var(--padding4);
    border-radius: var(--roundCorner2);
    border: none;
    font-size: var(--inputFontSize);
    background-color: var(--backgroundColor2);
    margin: 0 0 0 10px;
}

.dataTables_wrapper .dataTables_filter {
    margin-top: 0 !important;
}

.dataTables_wrapper .dataTables_length select[name="irctable_length"] {
    margin: 0 5px;
}

.dataTables_wrapper .dataTables_length label {
    margin: 0;
}



.table-container {
    overflow-x: hidden;
}

    .table-container:hover {
        overflow-x: scroll;
    }

.dataTables_length > label > select {
    border-radius: 50px;
    padding: 7px 35px 7px 15px;
    border: none;
    font-size: 13px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22caret-down%22%20class%3D%22svg-inline--fa%20fa-caret-down%20fa-w-10%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M31.3%20192h257.3c17.8%200%2026.7%2021.5%2014.1%2034.1L174.1%20354.8c-7.8%207.8-20.5%207.8-28.3%200L17.2%20226.1C4.6%20213.5%2013.5%20192%2031.3%20192z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E);
    background-position: right 1.1em top 54%, 0 0;
    background-size: .65em auto, 100%;
    background-repeat: no-repeat, repeat;
    background-color: #F5F5F5;
}

/* -------------------- Head -------------------- */
table.table th:not(.dataTables_scrollBody thead th, .DTFC_LeftBodyWrapper thead th, .DTFC_RightBodyWrapper thead th) {
    padding-right: 30px;
    position: relative;
    background: var(--styleColor3) !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    text-align: center;
    /*border-right: 2px solid #333;*/
}

table.table th:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

table.table th:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-right: none;
}

.table thead th {
    font-size: unset;
}

/* -------------------- Body -------------------- */

.DTFC_LeftBodyLiner {
    overflow: hidden;
}

table.table tr:nth-of-type(2n+1) {
    background: #FFFFFF;
}

    table.table tr input.form-control {
        background: var(--backgroundColor1);
        ;
    }

    table.table tr:nth-of-type(2n+1) input.form-control {
        background: var(--backgroundColor2);
        ;
    }

table.table > tbody > tr {
    background: #F5F5F5;
    height: 29px;
}

table.table td {
    border: none;
    text-align: center;
}

    table.table td:first-child {
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }

    table.table td:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }

table.table td, table.table th {
    padding: 5px 15px;
    position: relative;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    vertical-align: middle;
    width: 100%;
}

    table.table td abbr, table.table th abbr {
        height: 16px;
    }

.row-dropdown {
    display: none;
    min-width: 150px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    /*top: 40px;*/
    right: 0;
    background: #FFF;
    -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 0;
    border-radius: 10px;
    -webkit-transform: translate(0, 11px);
    -ms-transform: translate(0, 11px);
    transform: translate(0, 11px);
    z-index: 100;
}

    .row-dropdown > ul > li {
        margin: 0;
        padding: 0;
    }

        .row-dropdown > ul > li:first-child {
            margin-top: 10px;
        }

        .row-dropdown > ul > li:last-child {
            margin-bottom: 10px;
        }

        .row-dropdown > ul > li > * {
            font-weight: 700;
            width: 100%;
            height: 100%;
            display: block;
            padding: 5px 20px;
            text-align: right;
            cursor: pointer;
        }

            .row-dropdown > ul > li > *:hover,
            .row-dropdown > ul > li > a:not([href]):not([tabindex]):focus,
            .row-dropdown > ul > li > a:not([href]):not([tabindex]):hover {
                color: $default-font-color-dark;
                background: var(--backgroundColor2);
            }

        .row-dropdown > ul > li > a[onclick="rowDropdown(this)"]:not([href]):not([tabindex]):focus,
        .row-dropdown > ul > li > a[onclick="rowDropdown(this)"]:not([href]):not([tabindex]):hover {
            color: $default-font-color-dark;
        }

.table.dataTable[class*=table-] thead th:first-child {
    border-radius: 10px 0 0 10px;
}

.table.dataTable[class*=table-] thead th:last-child {
    border-radius: 0 10px 10px 0;
}

    .table.dataTable[class*=table-] thead th:last-child:empty {
        height: 19px;
    }

.DTFC_LeftHeadWrapper thead th:only-child {
    border-radius: 10px 0 0 10px !important;
}

.DTFC_RightHeadWrapper thead th:only-child {
    border-radius: 0 10px 10px 0 !important;
}

.datatable {
    position: relative;
}

div.DTFC_LeftHeadWrapper table, div.DTFC_RightHeadWrapper table {
    background-color: #FFF;
    overflow: hidden;
}

.dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_LeftWrapper > div.DTFC_LeftHeadWrapper > table > thead > tr > th {
    border-radius: 0;
    height: 19px;
}

.dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_LeftWrapper > div.DTFC_LeftHeadWrapper > table > thead > tr > th:first-child {
    border-radius: 10px 0 0 10px;
}

.dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_RightWrapper > div.DTFC_RightBodyWrapper > div > table > thead, .dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_LeftWrapper > div.DTFC_LeftBodyWrapper > div > table > thead {
    display: none;
}

.dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_LeftWrapper > div.DTFC_LeftBodyWrapper > div > table > tbody > tr, .dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_RightWrapper > div.DTFC_RightBodyWrapper > div > table > tbody > tr {
    width: 100%;
    /*display: block;*/
    background-color: #FFF;
    display: inline-flex;
}

    .dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_LeftWrapper > div.DTFC_LeftBodyWrapper > div > table > tbody > tr:nth-of-type(2n) > td, .dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_RightWrapper > div.DTFC_RightBodyWrapper > div > table > tbody > tr:nth-of-type(2n) > td {
        background: #F5F5F5;
    }

    .dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_RightWrapper > div.DTFC_RightBodyWrapper > div > table > tbody > tr > td {
        min-width: 50px;
        max-width: 50px;
    }

table.dataTable.table-striped.DTFC_Cloned tbody, table.DTFC_Cloned tr {
    width: 100%;
    display: block;
}

.dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_LeftWrapper > div.DTFC_LeftBodyWrapper > div > table > tbody > tr > td {
    border-radius: 0;
    width: calc(100% - 60px);
    display: block;
}

.dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_LeftWrapper > div.DTFC_LeftBodyWrapper > div > table > tbody > tr > td:first-child {
    border-radius: 10px 0 0 10px;
}

.DTFC_LeftHeadWrapper .table:hover, .DTFC_RightHeadWrapper .table:hover, .DTFC_ScrollWrapper .dataTables_scrollHead .table:hover {
    overflow-x: unset;
}

.table.table-sm td:first-child, .table.table-sm th:first-child {
    border-radius: 10px 0 0 10px;
}

.table.table-sm td:last-child, .table.table-sm th:last-child {
    border-radius: 0 10px 10px 0;
}

.dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_RightWrapper > div.DTFC_RightBodyWrapper > div {
    overflow: visible !important;
    width: unset !important;
}

.dataTables_wrapper > div.DTFC_ScrollWrapper > div.DTFC_RightWrapper > div.DTFC_RightBodyWrapper {
    overflow: visible !important;
}

div.DTFC_LeftBodyWrapper table, div.DTFC_RightBodyWrapper table {
    overflow: visible !important;
}

div.DTFC_ScrollWrapper > .dataTables_scroll > .dataTables_scrollHead > .dataTables_scrollHeadInner > table {
    overflow: hidden;
}

.module-dropdown > ul, .row-dropdown > ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

td.select-checkbox, th.select-checkbox {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
}

td a:not([href]):not([tabindex]) {
    color: $default-font-color-dark;
    cursor: pointer;
}

    td a:not([href]):not([tabindex]).content-btn {
        color: $default-font-color-light;
    }

.visible-item {
    display: block;
}

td a:not([href]):not([tabindex]) .content-btn-1, td a:not([href]):not([tabindex]) .content-btn-2, td a:not([href]):not([tabindex]) .content-btn-3, td a:not([href]):not([tabindex]) .content-btn-4, td a:not([href]):not([tabindex]) .content-btn-5 {
    color: unset;
}

/*thead > tr > th {
    min-width: 20px !important;
    max-width: 20px !important;
}*/

#modalspace .modal .module-dataTable table tbody tr {
    cursor: pointer;
}

#OrderDetailsByOrderId .DTFC_LeftWrapper tbody tr {
    cursor: pointer;
}

    #OrderDetailsByOrderId .DTFC_LeftWrapper tbody tr td {
        text-decoration: underline;
    }
    
    
// Disable filter
.disable-grid-filter {
    .hide-on-disable-grid-fliter {
        display: none;
    }
}