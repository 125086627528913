﻿.module-content-shop {
    width: 100%;
    padding: 0;

    &, .module-content-productFilter, .module-content-productResults {
        @include gridColumns(400px);
        gap: 25px;
    }

    .module-content-productFilter {
        grid-column: 1 / 2;
    }

    .module-content-productResults {
        grid-column: 2 / -1;
    }

    .module-content-shop-product {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-column-gap: 25px;
        -moz-column-gap: 25px;
        gap: 25px;
        padding: 15px;
        border: 5px solid #F5F5F5;
        border-radius: 10px;
        cursor: pointer;

        @include size(mobile) {
            flex-flow: row wrap;
        }

        &.proposal.checked {
            border: 5px solid rgb(21, 195, 154);
            border-radius: 10px;
            background-color: rgba(21,195,154, 0.2);
        }

        .module-content-shop-product-image {
            width: 30%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            /*-webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;*/

            @include size(mobile) {
                width: 100%;
                justify-content: center;
            }

            img {
                max-width: 100%;
                max-height: 150px;
                height: fit-content;
                border-radius: 10px;
            }
        }

        .module-content-shop-product-info {
            width: 70%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            height: -webkit-fit-content;
            height: fit-content;
            height: -moz-fit-content;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;

            @include size(mobile) {
                width: 100%;
            }

            h5 {
                width: 100%;
                margin: 0 0 5px 0;
            }

            p {
                width: 100%;
                margin: 0 0 15px 0;
            }

            ul {
                width: 100%;
                margin: 0 0 15px 0;
                padding: 0 0 0 15px;
                /*list-style: none;*/

                ul {
                    margin: 0;
                }
            }

            .module-content-shop-product-info-pricing {
                width: 100%;
                display: -webkit-inline-box;
                display: -ms-inline-flexbox;
                display: inline-flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                h5 {
                    margin: 0;
                    width: unset;
                }
            }
        }
    }

    .shop-simulations {
        display: flex;
        flex-flow: row nowrap;
        gap: 25px;
        width: 100%;

        .shop-simulation {
            width: 100%;
            border: 5px solid #F5F5F5;
            border-radius: 10px;
            padding: 15px;

            & > h5 {
                border-bottom: $default-border-line;
                padding-bottom: 15px;
                margin-bottom: 15px;
                text-align: center;
                font-weight: 900;
            }

            details {
                /*border: 1px dotted red;*/
                width: 100%;
                border: none;
                padding: 0;

                &:not(:last-child) {
                    padding: 0 0 15px 0;
                }

                &[open] {
                    /*border-bottom: $default-border-line;*/

                    & > summary {
                        padding: 5px 15px;
                        border: none;

                        &:before {
                            all: unset;
                        }

                        &:after {
                            font-family: "Font Awesome 5 Free";
                            content: '\f106';
                            font-weight: 700;
                            position: absolute;
                            right: 15px;
                            font-size: 17px;
                            top: 8px;
                        }

                        & > .option-title {
                            margin: 0;
                        }

                        & > .option-summary {
                            display: none;
                        }
                    }
                }

                &.minimized {
                    opacity: 0.5;

                    summary {
                        border: none;
                        padding: 15px;

                        &:before {
                            all: unset;
                        }

                        &:hover {
                            cursor: not-allowed;
                        }
                    }
                }

                summary {
                    background: #f5f5f5;
                    border-radius: 10px;
                    padding: 15px;
                    border: 5px solid $default-style-color-1;
                    padding-left: 30px;
                    position: relative;

                    &:before {
                        font-family: "Font Awesome 5 Free";
                        content: '\f105';
                        font-weight: 700;
                        position: absolute;
                        left: 15px;
                        font-size: 17px;
                        top: 18px;
                    }

                    .option-title {
                        font-weight: 700;
                        font-size: 1.4em;
                        font-weight: var(--fontWeight3);
                        margin-bottom: 5px;
                    }

                    .option-summary {
                        /*font-weight: 700;*/
                    }
                }
            }
            /*details[open] ~ details {
                display: none;
            }*/
            /*details[open] & {
                display: none;

                &[open] {
                    display: unset;
                }
            }*/

            ul {
                padding: 0;
                margin: 0;

                &:not(:last-child) {
                    margin-bottom: 25px;
                }

                h5 {
                    margin-bottom: 5px;
                }

                li {
                    list-style: none;
                    display: flex;
                    column-gap: 10px;
                    align-items: center;
                    cursor: pointer;

                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}
