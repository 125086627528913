﻿.form-input {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;

    &:not(:last-child) {
        margin-bottom: 10px;
        
        @include size(tablet) {
            margin-bottom: 25px;
        }
    }

    label, input, textarea, select {
        width: 100%;
    }

    label {
        font-weight: 700;
        text-align: left;
        margin-bottom: 0.5em;
        padding: var(--padding5);
    }

    input, textarea, select {
        /*background: $default-style-color-5;*/
        background: white;
        appearance: none;
        padding: 5px 15px;
        margin: 0;
        border-radius: var(--roundCorner2);
        border: none;
        height: 100%;
        padding: 5px 35px 5px 15px !important;
    }

    .option {
        font-style: italic;
        font-weight: 600;
        /*color: $default-style-color-1;*/
        color: var(--styleColor1);
        margin-top: 0.5em;
        cursor: pointer;
        padding: var(--padding5);
        @include hoverStyle(link);

        @include size(tablet) {
            margin-top: 12px;
        }

        &[data-active] {
            display: none;
        }
    }
}

.form-group {


    & > p {
        padding: var(--padding5);
    }
}