﻿.module-block {
  /*position: relative;*/
}

.chart-no-data-message {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    color: white;
    /*text-shadow: 2px 1px #00000055;*/
    backdrop-filter: blur(1px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--marginSides1);
    border-radius: 15px;

    * {
        color: white;
    }
}


@media only screen and (max-width: 768px), only screen and (max-device-width: 896px) and (orientation: landscape) {
  .chart-no-data-message {
    display: none;
  }
}