﻿.content {
    background: $default-background-color;
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    padding: $default-menu-padding;
    flex: 1 1 auto;
    overflow-y: auto;
    display: flex;
    flex-flow: column nowrap;
    position: relative;

    @include size(tablet) {
        height: calc(100vh - 60px) !important;
    }

    .header {
        width: 100%;
        margin-bottom: 50px;
    }

    .info {
        &:not(:empty) {
            margin-bottom: 25px;
        }
        
        //display: flex;
        //flex-flow: column nowrap;

        @include size(tablet) {
            //background: var(--backgroundColor2);
            //border-radius: 10px;
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-end;
            
            h1, h5 {
                width: fit-content;
            }
        }
    }

    .body {
        padding-bottom: $default-body-padding-bottom;
    }
}

.tabs {
    display: flex;
    width: 100%;
    padding: 0 10px;
    margin: 0;
    list-style: none;

    .tab {
        padding: 5px 10px;
        background: var(--backgroundColor2);
        border-radius: 10px 10px 0 0;
        cursor: pointer;

        &.active {
            //padding-top: 15px;
            background: var(--styleColor3);
        }
    }
}

.actions {
    background: $default-style-color-5;
    width: 100%;
    padding: $default-menu-padding;
    border-radius: 10px;
    display: flex;
    gap: 15px;
    margin-bottom: 25px !important;
    flex-flow: row wrap;

    @include size(tablet) {
        justify-content: center;
        
        //background: rgba(255, 255, 255, 0.35);
        background: rgba(255, 255, 255, 0.5);
        //background: rgba(245, 245, 245, 0.5);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        //backdrop-filter: blur(7px);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(7px);

        position: sticky;
        top: 0;
        z-index: 100;
    }
    

    .left, .right {
        display: flex;
        gap: 10px;
        flex-flow: row wrap;

        &.right {
            margin-left: auto;
        }

        @include size(tablet) {
            justify-content: center;
            margin: 0 !important;
        }
    }

    .item-container {
        display: flex;
        flex-flow: column wrap;
        
        &.column {
            flex-flow: column wrap;
        }
        
        &.row {
            flex-flow: row wrap;
            width: 100%;
            justify-content: space-between;
            
            .module-content-tab-btns {
                padding: 0;
            }
        }

        &:not(:last-child) {
            margin-right: 10px;
            padding-right: 10px;
            border-right: var(--borderBottom1);
        }

        h5 {
            text-align: left;
        }
    }
}

.messages {
    position: sticky;
    top: 0;
    z-index: 1;
    //margin-bottom: 20px;
    
    .actions {
        background: none;
        padding: 0;
        display: flex;
        flex-direction: column;
    }
}

.MuiAccordion-root {
    border-radius: 10px !important;
    overflow: hidden;
    
    //&:not(.Mui-expanded) {
    //    position: sticky;
    //    top: 0;
    //}
    
    &.Mui-expanded {
        overflow: unset;
    }
    
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    
    &.Mui-expanded {
        border-radius: 10px 10px 0 0;
    }
    
    .actions {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: var(--borderBottom1);
    }

    .MuiAccordionSummary-root{
        //position: sticky;
        //top: 0;
        
        &:before {
            all: unset;
            display: none !important;
        }
    }
    
    .content-masonry {
        margin-top: 20px;
    }
}