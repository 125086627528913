﻿.module-options-active {
    display: block;
    z-index: 1;
}

.module-content-disable {
    display: none;
}

.show-legend-item-dropdown {
    display: block;
}

.legend-item-absolute {
    position: absolute !important;
}

.legend-item-group.hide {
    display: none;
}

.show-mobile-legend {
    display: block;
    height: 80vh;

    .module-colapse {
        display: none;
    }
}

.showSettings {
    display: block;
}

.chart-spinner-hide {
    display: none;
}

.no-margin {
    margin: 0;
}

.width-unset {
    width: unset !important;
}

.hide {
    display: none !important;
}

.hidden {
    display: none !important;
}

.translation-missing-img {
    margin-left: 5px;
}

.clickableTable {
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.clickable {
    cursor: pointer !important;
}