﻿$sidebar-width: 50px;

.sidebar {
    background: $default-style-color-4;
    width: $sidebar-width;
    max-height: 100vh;
    height: 100vh;
    padding: $default-menu-padding;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column wrap;
    gap: 10px;

    img {
        width: 100%;
    }

    .icon {
        width: $sidebar-width - ($default-menu-padding * 2);
        height: $sidebar-width - ($default-menu-padding * 2);
        color: $default-font-color-light;
        position: relative;

        .link {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: $default-font-color-light;

            @include hoverStyle(icon, i);
            /*&:not(p) {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: $default-font-color-light;

                @include hoverStyle(icon, i);
            }*/

            i, svg {
                font-size: 23px;
            }
        }

        p.link {
            display: unset;
        }

        &.indicator:after {
            content: "3";
            font-weight: 600;
            font-size: 9px;
            background: $default-style-color-red;
            width: 12px;
            height: 12px;
            border-radius: 10px;
            position: absolute;
            right: -2px;
            top: -2px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: white;
        }

        &.circle-background {
            background: $default-style-color-5;
            border-radius: 50px;
            color: $default-font-color-dark;

            i, svg {
                font-size: 16px;
            }

            .link {
                color: $default-font-color-dark;
            }
        }

        &.bottom-row-start {
            margin-top: auto;
        }
        
        &.marketplace {
            svg {
                font-size: 20px;
            }
        }
    }

    .application {
        cursor: pointer;
        max-width: 100%;
        display: flex;
        flex-flow: column nowrap;
        gap: 10px;

        * {
            user-select: none;
        }

        .application-current {
            position: relative;
            height: 30px;

            //&:after {
            //    content: "\f107";
            //    font-family: "Font Awesome 5 Free";
            //    font-weight: 700;
            //    font-size: 11px;
            //    background: $default-style-color-1;
            //    width: 12px;
            //    height: 12px;
            //    border-radius: 10px;
            //    position: absolute;
            //    right: -2px;
            //    bottom: -2px;
            //    display: flex;
            //    justify-content: center;
            //    align-items: center;
            //    padding-top: 1px;
            //    cursor: pointer;
            //    color: white;
            //}
            
            .dropdown-indicator {
                font-size: 11px;
                font-weight: 700;
                background: $default-style-color-1;
                width: 12px;
                height: 12px;
                border-radius: 10px;
                position: absolute;
                right: -2px;
                bottom: -2px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 1px;
                cursor: pointer;
                color: white;
            }
        }

        .application-list {
            display: flex;
            flex-flow: column nowrap;
            gap: 10px;

            .application-option {
                position: relative;
            }
        }
    }
}

.options {
    position: absolute;
    background: $default-background-color;
    border-radius: $default-border-radius;
    border: none;
    box-shadow: var(--shadow2);
    width: max-content;
    min-width: 150px;
    max-width: 350px;
    height: fit-content;
    /*padding: $default-padding;*/
    padding: $default-padding 0;
    user-select: none;
    z-index: 101;
    
    /*&:not(.hide) {

    }*/
    
    &.top {
        bottom: calc(100% + #{$default-menu-padding});
        left: 0;
    }
    
    &.bottom {
        top: calc(100% + #{$default-menu-padding});
        left: 0;
    }
    
    &.left {
        right: calc(100% + #{$default-menu-padding});
        bottom: 0;
    }
    
    &.right {
        left: calc(100% + #{$default-menu-padding});
        bottom: 0;
    }

    & > * {
        padding-left: $default-padding;
        padding-right: $default-padding;
    }

    .name {
        font-weight: 700;
        font-size: 1.3em;
        margin-bottom: 0.2em;
    }

    .role {
        font-style: italic;
    }

    .option {
        cursor: pointer;
        color: $default-font-color-dark;
        display: block;
        position: relative;

        @include hoverStyle(link);
        @include hoverStyle(link, '> p');

        &.active {
            background: $default-style-color-detail;
        }

        .options {
            left: 100%;
            bottom: 0;
        }
    }

    hr {
        margin: 10px auto;
        /*width: #{calc(100% - ($default-padding * 4))};*/
        width: calc(100% - (#{$default-padding} * 4));
    }

    //.module-content-tab-content, .module-content-tab-btns {
    //    padding: unset;
    //}

    p, a {
        margin-bottom: 0;
        line-height: $default-menu-lineHeight;
    }

    .sponsors {
        width: 100%;

        * {
            width: fit-content;
        }

        .sponsor {
            display: flex;
            flex-flow: column wrap;

            img {
                width: clamp(150px, 150px, 100%);
            }
        }
    }
}

* {
    .explanation {
        visibility: hidden;
        width: max-content;
        max-width: 250px;
        background-color: $default-style-color-4;
        color: $default-font-color-light;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        user-select: none;
        pointer-events: none;
        
        &.top {
            bottom: 100%;
            left: 50%;
            transform: translate(-50%, 0);

            &:after {
                top: 100%;
                left: 50%;
                border-color: $default-style-color-4 transparent transparent transparent;
                transform: translate(-50%, 0);
            }
        }
        
        &.bottom {
            top: 100%;
            left: 50%;
            transform: translate(-50%, 0);

            &:after {
                bottom: 100%;
                left: 50%;
                border-color: transparent transparent $default-style-color-4 transparent;
                transform: translate(-50%, 0);
            }
        }
        
        &.left {
            top: 50%;
            right: 100%;
            transform: translate(0, -50%);

            &:after {
                top: 50%;
                left: 100%;
                border-color: transparent transparent transparent $default-style-color-4;
                margin-top: -5px;
            }
        }
        
        &.right {
            top: 50%;
            left: 100%;
            transform: translate(0, -50%);

            &:after {
                top: 50%;
                right: 100%;
                border-color: transparent $default-style-color-4 transparent transparent;
                margin-top: -5px;
            }
        }

        @include size(tablet) {
            display: none;
        }

        &:after {
            content: "";
            position: absolute;
            border-width: 5px;
            border-style: solid;
        }
    }

    @include hoverStyle('', '> .explanation') {
        visibility: visible;
    }
}

.notification {
    font-weight: 600;
    font-size: 11px;
    background: $default-style-color-red;
    /*width: 12px;
    height: 12px;*/
    padding: 2px 5px;
    border-radius: 10px 10px 10px 0;
    position: absolute;
    /*right: -2px;*/
    top: -7px;
    left: calc(100% - 8px);
    display: flex;
    justify-content: center;
    align-items: center;
    /*cursor: pointer;*/
    color: white;
    user-select: none;
    z-index: 1;
}