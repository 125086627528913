﻿.modal-spinner {
  display: none;
}

.modal-spinner.show {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(1px);
  background-color: rgba(0,0,0,.3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.modal-loading-containter {
  position: relative;
}

.search-modal {
  z-index: 10000;
}