﻿.column-layout {
    .row {
        width: 100%;

        p {
            border-radius: 5px;
            padding: 3px 5px;

            &.done {
                background: var(--buttonColorGreen);
                color: white;
                user-select: none;
            }

            &.open {
                background: var(--buttonColorGrey);
                color: white;
                user-select: none;
            }
        }

        &:not(.header) {
            border-radius: $default-border-radius;

            &:nth-child(2n+1) {
                background: $default-style-color-5;
            }

            &:hover {
                background: $default-style-color-5;
            }
        }

        &.header {
            border-bottom: $default-border-line;
            margin: 0;

            p {
                font-weight: 700;
            }
        }

        &.opened {
            .head {
                border-bottom: $default-border-line;

                p {
                    font-weight: 700;
                }
            }
        }

        .column {

            img {
                /*width: clamp(150px, 100%, 500px);*/
                width: 100%;
                cursor: pointer;
            }
        }

        .head {
            width: 100%;
            display: grid;
            grid-template-columns: 80px 1fr min-content;
            gap: 15px;
            cursor: pointer;
            padding: 5px 10px;
        }

        .body {
            width: 100%;
            display: grid;
            grid-template-columns: 30% 1fr;
            gap: 15px;
            padding: 10px 10px;
        }
    }
}
