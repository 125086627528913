﻿/*.pcoded-navbar.menu-light a span {
    color: white;
}*/
.btn-irc-grid {
    padding: 0px 10px !important;
    margin-bottom: 0px !important;
}

.tab-content {
    background: none;
    box-shadow: none;
}

.bg-c-red .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background: linear-gradient(45deg,#e52d27,#b31217);
}


.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background: linear-gradient(45deg,#2ca961,#2ed88a);
}

.bg-c-red .btn-red {
    color: #fff;
    background-color: transparent;
    border-color: red;
}

.bg-c-green .btn-green {
    color: #fff;
    background-color: transparent;
    border-color: green;
}

.bg-c-blue .btn-blue {
    color: #fff;
    background-color: transparent;
    border-color: blue;
}

.bg-c-red .tab-content, .bg-c-green .tab-content, .tab-content h2, .bg-c-red .card-body, .bg-c-green .card-body, .bg-c-blue .card-body, .bg-c-yellow .card-body {
    background-color: #fff;
    color: #686c71;
    padding: 15px !important;
}

.bg-c-red .card-footer, .bg-c-green .card-footer, .bg-c-blue .card-footer {
    background-color: #fff;
    color: #686c71;
    text-align: center;
}

.card-title {
    padding: 20px 20px 0px 20px;
}

.nav-tabs .nav-link {
    border: none;
    color: #686c71;
}

.carousel-inner > .carousel-item {
    /*height: 360px !important;*/
    text-align: center;
    border: 1px solid rgba(42,42,42,.05);
    background: rgba(204,204,204,.15);
}

    .carousel-inner > .carousel-item > img {
        max-height: 100%;
        width: auto;
    }

.dropzone {
    margin-top: 30px;
}

.order-card h2 {
    color: #686c71;
}

.nav-pills {
    padding: 0px;
}

.dropzone {
    border: 2px dashed #0087F7;
    border-radius: 5px;
    background: rgba(204,204,204,.15);
}

    .dropzone.dz-clickable {
        cursor: pointer;
    }

.image-delete-button {
    opacity: 0.1;
    transition: opacity 333ms ease-out;
}

.carousel:hover .image-delete-button {
    opacity: 0.8;
}

.navbar-select-wrapper, .navbar-collapsed:hover .navbar-select-wrapper {
    margin-left: 25px;
    margin-right: 25px;
    max-width: calc(100% - 50px);
}

.navbar-collapsed .navbar-select-wrapper {
    margin-left: 10px;
    margin-right: 10px;
    max-width: calc(100% - 20px);
}

.pcoded-navbar .pcoded-inner-navbar li {
    margin-right: 20px;
}

.error {
    color: red;
    border-color: red;
}

.show-if-max-images, .max-images .hide-if-max-images, #PermissionSection {
    display: none;
}

.max-images .show-if-max-images {
    display: initial
}

*[has-permission] {
    transition: border 333ms ease-in-out;
}

.edit-permissions-active *[has-permission] {
    border: red 2px dashed;
}

.edit-permissions-active #toggle-permission-edit-button {
    background-color: green;
}

.edit-permissions-active #PermissionSection {
    display: flex;
}

.input-group {
    background: none;
}

.tooltip {
    z-index: 100000000;
}

#exportBtn {
    position: absolute;
    right: 0;
    bottom: -35px;
}

.export-div {
    position: relative;
}

.show-on-advanced-search {
    display: none;
}

.advanced-search .show-on-advanced-search {
    display: block;
}

.advanced-search .hide-on-advanced-search {
    display: none;
}

.datatable {
    position: relative
}

    .datatable.loading {
        margin-top: 50px !important;
        margin-bottom: 57px !important;
    }

        .datatable.loading tbody {
            position: relative;
        }

.advanced-search .show-on-advanced-search {
    min-height: 150px;
}

.datatable.loading tbody:after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
    animation: slide 1s infinite;
    background: linear-gradient(120deg, rgba(255,255,255,0) 40%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 60%); /* W3C */
    background-size: 200% 100%;
}
